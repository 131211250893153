import React from "react";
import {Helmet} from "react-helmet";

function About() {
    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="Why we built the Special Gift Ideas website." />
        </Helmet>        
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                 At <a className="text-sky-500 hover:text-sky-600 font-bold text-base" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft</a>, 
                 we are always eager to show our friends and family members that they are essential to us. From that idea that one should sometimes thank the people around him for their support, we created this
                  <span className="font-bold"> Special Gift Ideas website </span> 
                  to recommend unique gifts that can bring joy to those you care about. <br />  
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;