import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Hindu(){
    return(
        <div>
            <br /><br />
            <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Our Gift ideas for Hindu festivals." />
            <meta property="og:description" content="Learn about Hindu festivals and the best gift ideas for these occasions." />
            </Helmet>            
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Hindu festivals</h1>
                <ArticleListing category={"hindu"}></ArticleListing>
            </section>
        </div>
    )
}

export default Hindu;